import ApiService from "../common/api.service";


export const tks = {
  namespaced: true,
  state: null,
  actions: {
    getListJumlahPinjaman() {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get("tks/listJumlahPinjaman")
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	searchingStaff(context, term) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("tks/searchingStaff", { 'term' : term })
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	billingTransactionHistory(context, studentNo) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("tks/billingTransactionHistory", { 'studentNo' : studentNo })
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	billingUnpaid(context, studentNo) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("tks/billingUnpaid", { 'studentNo' : studentNo })
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	storeKos12(context, model) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("tks/store/kos12", model)
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	uploadFile(context, model) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.setHeaderMultipart();
		ApiService.post("tks/store/uploadFile", model)
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	downloadFile(context, model) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.postFile("tks/store/downloadFile", model)
			.then((response) => {
				resolve(response);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	downloadOfferLetter(context, model) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.postFile("tks/store/downloadOfferLetter", model)
			.then((response) => {
				resolve(response);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	store(context, model) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("tks/store", model)
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	read(context, studentNo) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("tks/read", { 'studentNo' : studentNo })
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
  },
  mutations: {
    
  }
};