const ID_TOKEN_KEY = "id_token_hi";
const ID_TOKEN_JWT_HI = "id_token_jwt_hi";
const URL_JWT_KEY = "url_jwt_hi";
const FLAG_HI_KEY = "flag_hi";
const FLAG_ANSWERED_APP_KEY = "flag_answered_app_hi";
const FLAG_ANSWERED_DB_KEY = "flag_answered_db_hi";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};


export const getTokenJWT = () => {
  return window.localStorage.getItem(ID_TOKEN_JWT_HI);
};

export const saveTokenJWT = tokenJWT => {
  window.localStorage.setItem(ID_TOKEN_JWT_HI, tokenJWT);
};

export const destroyTokenJWT = () => {
  window.localStorage.removeItem(ID_TOKEN_JWT_HI);
};


export const getUrlJWT = () => {
  return window.localStorage.getItem(URL_JWT_KEY);
};

export const saveUrlJWT = urlJWT => {
  window.localStorage.setItem(URL_JWT_KEY, urlJWT);
};

export const destroyUrlJWT = () => {
  window.localStorage.removeItem(URL_JWT_KEY);
};


export const getFlagHI = () => {
  return window.localStorage.getItem(FLAG_HI_KEY);
};

export const saveFlagHI = flagHI => {
  window.localStorage.setItem(FLAG_HI_KEY, flagHI);
};

export const destroyFlagHI = () => {
  window.localStorage.removeItem(FLAG_HI_KEY);
};


export const getFlagAnsweredApp = () => {
  return window.localStorage.getItem(FLAG_ANSWERED_APP_KEY);
};

export const saveFlagAnsweredApp = flagAnsweredApp => {
  window.localStorage.setItem(FLAG_ANSWERED_APP_KEY, flagAnsweredApp);
};

export const destroyFlagAnsweredApp = () => {
  window.localStorage.removeItem(FLAG_ANSWERED_APP_KEY);
};


export const getFlagAnsweredDB = () => {
  return window.localStorage.getItem(FLAG_ANSWERED_DB_KEY);
};

export const saveFlagAnsweredDB = flagAnsweredDB => {
  window.localStorage.setItem(FLAG_ANSWERED_DB_KEY, flagAnsweredDB);
};

export const destroyFlagAnsweredDB = () => {
  window.localStorage.removeItem(FLAG_ANSWERED_DB_KEY);
};

export const destroyStorageHI = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_JWT_HI);
  window.localStorage.removeItem(URL_JWT_KEY);
  window.localStorage.removeItem(FLAG_HI_KEY);
  window.localStorage.removeItem(FLAG_ANSWERED_APP_KEY);
  window.localStorage.removeItem(FLAG_ANSWERED_DB_KEY);
};

export default { 
                 getToken, saveToken, destroyToken,
                 getTokenJWT, saveTokenJWT, destroyTokenJWT,
                 getUrlJWT, saveUrlJWT, destroyUrlJWT,
                 getFlagHI, saveFlagHI, destroyFlagHI,
                 getFlagAnsweredApp, saveFlagAnsweredApp, destroyFlagAnsweredApp,
                 getFlagAnsweredDB, saveFlagAnsweredDB, destroyFlagAnsweredDB,
                 destroyStorageHI,
              };