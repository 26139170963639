import ApiService from "../common/api.service";


export const program = {
	namespaced: true,
	state: null,
	actions: {
		downloadFile(context, model) {
			return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.postFile("program/downloadFile", model)
				.then((response) => {
					resolve(response);
				}).catch(({ response }) => {
	
					if(response.status == 401){
						console.log("These credentials do not match our records.");
					}else if( response.status == 500){
						console.log("General Error");
					}else if(response.status == 403){
						console.log("com.error.unauthorized");
					}else{
						console.log("Undefined Error");
					}
					reject(response);
				});
			});
		},
		uploadFile(context, model) {
			return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.setHeaderMultipart();
			ApiService.post("program/uploadFile/", model)
				.then(({ data }) => {
					resolve(data);
				}).catch(({ response }) => {
	
					if(response.status == 401){
						console.log("These credentials do not match our records.");
					}else if( response.status == 500){
						console.log("General Error");
					}else if(response.status == 403){
						console.log("com.error.unauthorized");
					}else{
						console.log("Undefined Error");
					}
					reject(response);
				});
			});
		},
		submitAttendent(context, url) {
			return new Promise((resolve, reject) => {
				ApiService.setHeader();
				ApiService.get(url)
					.then(({ data }) => {
						resolve(data);
					}).catch(({ response }) => {

						if(response.status == 401){
							console.log("These credentials do not match our records.");
						}else if( response.status == 500){
							console.log("General Error");
						}else if(response.status == 403){
							console.log("com.error.unauthorized");
						}else{
							console.log("Undefined Error");
						}
						reject(response);
				});
			});
		},
		lisProgram(context, studentNo) {
			return new Promise((resolve, reject) => {
				ApiService.setHeader();
				ApiService.get("program/listOfProgram/"+studentNo)
					.then(({ data }) => {
						resolve(data);
					}).catch(({ response }) => {

						if(response.status == 401){
							console.log("These credentials do not match our records.");
						}else if( response.status == 500){
							console.log("General Error");
						}else if(response.status == 403){
							console.log("com.error.unauthorized");
						}else{
							console.log("Undefined Error");
						}
						reject(response);
				});
			});
		},
		listOfProgramByProgramTypeNStudent(context, data) {
			return new Promise((resolve, reject) => {
				ApiService.setHeader();
				ApiService.get("program/listOfProgramByProgramTypeNStudent/"+data[1]+'/'+data[0])
					.then(({ data }) => {
						resolve(data);
					}).catch(({ response }) => {

						if(response.status == 401){
							console.log("These credentials do not match our records.");
						}else if( response.status == 500){
							console.log("General Error");
						}else if(response.status == 403){
							console.log("com.error.unauthorized");
						}else{
							console.log("Undefined Error");
						}
						reject(response);
				});
			});
		},
		listOfProgramByProgramType(context, data) {
			return new Promise((resolve, reject) => {
				ApiService.setHeader();
				ApiService.get("program/listOfProgramByProgramType/"+data[1]+'/'+data[0])
					.then(({ data }) => {
						resolve(data);
					}).catch(({ response }) => {

						if(response.status == 401){
							console.log("These credentials do not match our records.");
						}else if( response.status == 500){
							console.log("General Error");
						}else if(response.status == 403){
							console.log("com.error.unauthorized");
						}else{
							console.log("Undefined Error");
						}
						reject(response);
				});
			});
		},
		registerProgram(context, data) {
			return new Promise((resolve, reject) => {
				ApiService.setHeader();
				ApiService.get("program/registerProgram/"+data[1]+'/'+data[0]+'/'+data[2])
					.then(({ data }) => {
						resolve(data);
					}).catch(({ response }) => {

						console.log(response);

						if(response.status == 401){
							console.log("These credentials do not match our records.");
						}else if( response.status == 500){
							console.log("General Error");
						}else if(response.status == 403){
							console.log("com.error.unauthorized");
						}else{
							console.log("Undefined Error");
						}
						reject(response);
				});
			});
		},	
	},
	mutations: {

	}
};