import ApiService from "../common/api.service";


export const myEnt = {
  namespaced: true,
  state: null,
  actions: {
    getListBidangPerniagaan() {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get("myent/listBidangPerniagaan")
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {
				console.log(response);

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	getListStatusBumiputera() {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get("myent/listStatusBumiputera")
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	getListJenisMilikanPerniagaan() {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get("myent/listJenisMilikanPerniagaan")
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	getListPuncaModal() {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get("myent/listPuncaModal")
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	getListJumlahModalAwal() {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get("myent/listJumlahModalAwal")
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	getListBilanganPekerja() {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get("myent/listBilanganPekerja")
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	store(context, model) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("myent/store", model)
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {

				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
	read(context, studentNo) {
		return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("myent/read", { 'studentNo' : studentNo })
			.then(({ data }) => {
				resolve(data);
			}).catch(({ response }) => {
				if(response.status == 401){
					console.log("These credentials do not match our records.");
				}else if( response.status == 500){
					console.log("General Error");
				}else if(response.status == 403){
                    console.log("com.error.unauthorized");
                }else{
					console.log("Undefined Error");
				}
				reject(response);
			});
		});
	},
  },
  mutations: {
    
  }
};