import { initializeApp, getApps } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCzaZT_qsgrbWBmtFJ0Sg3I-eJbZtntbpM",
  authDomain: "universiti-tekno-1581783266917.firebaseapp.com",
  databaseURL: "https://universiti-tekno-1581783266917.firebaseio.com",
  projectId: "universiti-tekno-1581783266917",
  storageBucket: "universiti-tekno-1581783266917.appspot.com",
  messagingSenderId: "260902467644",
  appId: "1:260902467644:web:98053b55e4421ea6daeb39",
  measurementId: "G-SNQ6L86M6W"
};
let firebaseApp
const apps = getApps()
if (!apps.length) {
  firebaseApp = initializeApp(firebaseConfig)
} else {
  firebaseApp = apps[0]
}
const db = getFirestore()
const auth = getAuth(firebaseApp)

export { auth,db }