export const API_URL = process.env.VUE_APP_API_URL;
export const API_INTEGRASI_URL = process.env.VUE_APP_API_INTEGRASI_URL;

export const API_HI_URL = process.env.VUE_APP_API_HI_URL;
export const JWT_TOKEN_HI = process.env.VUE_APP_JWT_TOKEN_HI;

export const BEARER_TOKEN_MASMED = process.env.VUE_APP_BEARER_TOKEN_MASMED;
export const BEARER_TOKEN_EBOOK = process.env.VUE_APP_BEARER_TOKEN_EBOOK;
export const BEARRER_TOKEN_API = process.env.VUE_APP_BEARRER_TOKEN_API;
export const BEARER_TOKEN_KAD = process.env.VUE_APP_BEARER_TOKEN_KAD;
export const BEARER_TOKEN_PAY = process.env.VUE_APP_BEARER_TOKEN_PAY;

export default API_URL;