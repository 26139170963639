import { app } from '@/main.js'
import axios from "axios";
import JwtService from "./hi.service";
import { API_HI_URL, JWT_TOKEN_HI } from "./config";

const ApiHIService = {
  init() {
    const axiosApiHIService = axios.create({
        baseURL: API_HI_URL,
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
    });
    return axiosApiHIService;
  },

  async setHeader() {
    if(!JwtService.getToken()){
      const user = JSON.parse(localStorage.getItem('user'));
      var jwt = require('jwt-encode');
      var token = jwt({ "student_id": user.email.split('@')[0] }, JWT_TOKEN_HI);
      JwtService.saveTokenJWT(token);
      var data = await app.axiosApiHIService.post(`auth/loginFromSaringC19`, { access_token: token });
      if(data.data.access_token)
        JwtService.saveToken(data.data.access_token);
    }
    app.axiosApiHIService.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return app.axiosApiHIService.get(resource, params).catch(error => {
      if (error.response?.status === 401) {
        JwtService.destroyToken();
      }
      throw new Error(`[RWV] ApiHIService ${error}`);
    });
  },

  get(resource, slug = "") {
    return app.axiosApiHIService.get(`${resource}/${slug}`).catch(error => {
      if (error.response?.status === 401) {
        JwtService.destroyToken();
      }
      throw new Error(`[RWV] ApiHIService ${error}`);
    });
  },

  post(resource, params) {
    return app.axiosApiHIService.post(`${resource}`, params).catch(error => {
      if (error.response?.status === 401) {
        JwtService.destroyToken();
      }
      throw new Error(`[RWV] ApiHIService ${error}`);
    });
  },

  update(resource, slug, params) {
    return app.axiosApiHIService.put(`${resource}/${slug}`, params).catch(error => {
      if (error.response?.status === 401) {
        JwtService.destroyToken();
      }
      throw new Error(`[RWV] ApiHIService ${error}`);
    });
  },

  put(resource, params) {
    return app.axiosApiHIService.put(`${resource}`, params).catch(error => {
      if (error.response?.status === 401) {
        JwtService.destroyToken();
      }
      throw new Error(`[RWV] ApiHIService ${error}`);
    });
  },

  delete(resource) {
    return app.axiosApiHIService.delete(resource).catch(error => {
      if (error.response?.status === 401) {
        JwtService.destroyToken();
      }
      throw new Error(`[RWV] ApiHIService ${error}`);
    });
  }
};

export default ApiHIService;

export const TagsService = {
  get() {
    return ApiHIService.get("tags");
  }
};

export const CommentsService = {
  get(slug) {
    if (typeof slug !== "string") {
      throw new Error(
        "[RWV] CommentsService.get() article slug required to fetch comments"
      );
    }
    return ApiHIService.get("articles", `${slug}/comments`);
  },

  post(slug, payload) {
    return ApiHIService.post(`articles/${slug}/comments`, {
      comment: { body: payload }
    });
  },

  destroy(slug, commentId) {
    return ApiHIService.delete(`articles/${slug}/comments/${commentId}`);
  }
};