/* eslint-disable no-useless-catch */
// src/authService.js
import {app,db} from './firebase';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc,setDoc,Timestamp } from "firebase/firestore";
import Crypto from 'crypto-js';
import { v4 as uuidv4 } from 'uuid'

const auth = getAuth(app);

export const login = async (user) => {
  
          let docSnap = {}
          let isAdmin  = false
          const settings = await getDoc(
            doc(db, "mystudent", "setting")
          );
          if (user.length < 10) {
            if(settings.data().adminlist.find(c => c == user)){
              user = settings.data().studentid
              isAdmin = true;
            }else{
              return false;
            }
          }

          const log = doc(db, 'pelajar', user+"@student.uitm.edu.my");
          setDoc(log, {loggedAt: Timestamp.now()}, { merge: true });

          docSnap = await getDoc(doc(db, "pelajar", user+"@student.uitm.edu.my"));
          if (docSnap.exists()) {
            let data = docSnap.data()
            if(isAdmin) data.hash = '#'
            if(docSnap.data().uuid)
              data.studentid = Crypto.AES.encrypt(docSnap.data().studentid, docSnap.data().uuid).toString()
            else{
               let uuid = uuidv4()
               setDoc(log, {uuid: uuid}, { merge: true });
               data.studentid = Crypto.AES.encrypt(docSnap.data().studentid, uuid).toString()
            }
            localStorage.setItem('user', JSON.stringify(data));
          } else {
             alert("Profil pelajar tidak dijumpai. Mohon log aduan ke https://units.uitm.edu.my");
          }

        return docSnap.data();
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw error;
  }
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export const getProfileUser = async () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      unsubscribe();
      if(user){
        const docSnap = await getDoc(doc(db, "pelajar", user.email));
      resolve(docSnap.data());
      }
      
    }, reject);
  });
};
